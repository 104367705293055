import React from 'react';
import { graphql } from 'gatsby';
import {
  ComponentPreview,
  ColorDataTableDesign,
  DontDo,
  DontDoItem,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import utilityPreviewData from '../../../../data/previews/utility.yml';
import pageHeroData from '../../../../data/pages/guidelines.yml';
import styles from './styles/utility.module.scss';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Utility"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Utility" />

      <PageNavigation>
        <PageNavigationLink>Options</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Values</PageNavigationLink>
      </PageNavigation>

      <Section title="Options">
        <Paragraph>
          We have four utility colors to choose from, each with a very specific
          meaning and all distinct from one another.
        </Paragraph>
        <ComponentPreview
          name="UtilityType"
          layout="split"
          previewData={utilityPreviewData.options}>
          <div className={styles.defaultPreview} />
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Accessibility</SectionSubhead>
        <Paragraph>
          Utility colors do not reliably pass accessibility standards. Limit
          their use to graphic flourishes unless explicitly outlined in docs for
          an element, pattern or component.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use full intensity for a background or text."
            img="colors-utility-dont"
          />
          <DontDoItem
            type="do"
            text="use utility colors as accents to draw attention to nearby text."
            img="colors-utility-do"
          />
        </DontDo>
      </Section>

      <Section title="Values">
        <ColorDataTableDesign data={data} />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query utilityColorsDesign {
    allColorsYaml(filter: { type: { eq: "Utility" } }) {
      edges {
        node {
          type
          colors {
            value
            common_name
          }
        }
      }
    }
  }
`;
